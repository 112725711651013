import { Injectable } from '@angular/core'
import { App_Api as A } from '@ws/constants'
import { environment as env } from '@app/env'
import { Root_Api_Service, METHODS } from './index.service'
import { API_Event } from '@ws/contracts'

@Injectable({ providedIn: 'root' })
export class Api_Event {
  private readonly _route_base = {
    project: env.firebase_config.projectId,
    is_local: env.use_local,
  }

  constructor(
    private readonly _fs: Root_Api_Service,
  ) {}

  delete_event_date_key(
    event_id: string,
    delete_key: string
  ) {
    // /date-cap/:event_id/:replace_key
    const url = A.Event.patch_event_date_key(
      this._route_base,
      event_id,
      delete_key,
    )

    const params = { opts: { method: METHODS.DELETE }, url}
    return this._fs.fetch(params)
  }

  patch_date_key(
    event_id: string,
    old_key: string,
    dto: API_Event.I_Req_Patch_Event_Date_Key,
  ) {
    const url = A.Event.patch_event_date_key(
      this._route_base,
      event_id,
      old_key,
    )

    const params = { dto, opts: { method: METHODS.PATCH }, url}
    return this._fs.fetch<API_Event.I_Res_Put_Event>(params)
  }

  async put_event(dto: API_Event.I_Req_Put_Event) {
    const url = A.Event.put_event(this._route_base)
    const params = { dto, opts: { method: METHODS.PUT }, url }
    return await this._fs.fetch<API_Event.I_Res_Put_Event>(params)
  }

  join_event_as_org_member(
    event_id: string
  ) {

  }
}



