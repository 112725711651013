import { environment as E } from '@app/env'
import { User_Service } from '../user.service'
import { Injectable } from '@angular/core'
import safeStringifyJSON from 'json-stringify-safe'
import firebase from 'firebase/app';
import { HTTP_Interfaces as HI } from '@ws/constants'
import { Misc_Utils } from '../../utils/misc'
import { stringify } from 'flatted'

export enum METHODS {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

interface I_Fetch_Opts {
  // 'no-cache' default, no-cache, reload, force-cache, only-if-cached
  cache?: any
  // 'same-origin', include, *same-origin, omit
  credentials?: any
  // 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  method: METHODS
  // 'cors', no-cors, *cors, same-origin
  mode?: any
  // 'follow', manual, *follow, error
  redirect?: any
  // 'no-referrer', no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  referrerPolicy?: any

}

interface I_Fetch extends I_Fetch_Opts {
  // body data type must match "Content-Type" header
  body?: string
  headers: any
}

interface I_Fetch_Interface {
  dto?: any
  opts: I_Fetch_Opts
  url: string
}

@Injectable({ providedIn: 'root' })
export class Root_Api_Service {
  private readonly _pid: string

  constructor(
    private readonly _user_s: User_Service,
  ) {
    this._pid = E.firebase_config.projectId
  }

  async fetch<T>(p: I_Fetch_Interface) {
    const opts = await this._build_opts({ dto: p.dto, opts: p.opts })
    const res = await fetch(p.url, opts)
    if (res.status >= 300) {
      throw new Error(res.statusText)
    }
    return await res.json() as T
  }

  private async _get_auth_header() {
    const user = await this._user_s.get_user()

    if (!user) {
      console.error('User DNE')
      return
    }

    const token = await user.getIdToken()

    if (!token) {
      console.error('User token DNE')
      return
    }

    return `Bearer ${token}`
  }

  private async _build_opts(p: { dto: object, opts: I_Fetch_Opts }) {
    const val: I_Fetch = {
      ...p.opts,
      headers: {
        'Content-Type': 'application/json',
        Authorization: await this._get_auth_header(),
      }
    }

    if (p.dto) {
      val.body = safeStringifyJSON(p.dto)
    }

    return val
  }
}

// function swap_timestamps<T>(obje: T): any {
//   let count = 0
//   const max = 100

//   function recurse<J>(obj: J): J {
//     count += 1

//     if (count >= max) {
//       return obj
//     }

//     if (typeof obj === 'object') {
//       for (const key in obj) {
//         // tslint:disable-next-line
//         if (stringify(obj[key]) == stringify((Misc_Utils.get_fs_fieldvalue() as any))) {
//           obj[key] = HI.T_Timestamp_String as any
//         } else if (typeof obj[key] === 'object') {
//           swap_timestamps(obj[key])
//         }
//       }
//     }
//     return obj
//   }

//   if (count >= max) {
//     throw new Error('foobar')
//   }

//   return recurse<T>(obje)
// }
